import React from 'react';
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';



export function MenstrualHealth(props) {
    return (
        <div>
            
        </div>
    )
}